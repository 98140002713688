<template>
    <div class="supervisedetail">
        <full-scroll></full-scroll>
        <div class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            ">
            <div class="flex flex-pack-center flex-1">
                <img src="@/assets/national_emblem.png" alt="" class="msg-national m_r20" />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">
                    {{ tmpdata == 7 ? "意见建议收集详情" : tmpdata == 99 ? "心愿详情" : "" }}
                </div>
            </div>
            <div class="flex-end flex-1 flex" @click="backHome">
                <div class="my_text_center">
                    <img src="@/assets/images/backhome-new.png" alt="" class="msg-backhome m_b10" />
                    <p>返回首页</p>
                </div>
            </div>
        </div>

        <div class="msg-mid flex flex-v flex-justify-center flex-pack-center"
            v-if="this.$route.params.type == 99 || tmpdata == 99">
            <div class="my_text_center supervisedetail-box">
                <p class="
            font-size28 font-blod
            color_FF
            msg-mid-title-bot-title
        ">
                    {{ superviseDetail.title }}
                </p>
                <p class="font-size22 color_9FB msg-mid-title-bot-line">
                    {{ superviseDetail.releaseTime }} 发布
                </p>
                <van-popup v-model="showDoc" style="width: 60vw; padding: 2vw" closeable>
                    <div v-if="isShowDoc">
                        <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=https://gyrd.itlic.com' +
                            superviseDetail.annex
                            " frameborder="0" width="100%" height="800"></iframe>
                    </div>
                    <div v-else>
                        <div>
                            <embed :src="superviseDetail.annex" width="100%" height="800" />
                        </div>
                    </div>
                </van-popup>

                <div class="supervisedetail-desc">
                    <div class="font-size28 color_FF flex flex-end m_r20 m_b10"
                        v-if="superviseDetail.annex != 'null' && superviseDetail.annex != '[]'">
                        <van-button size="small" plain type="primary" class="m_r20" @click="preview">附件预览</van-button>
                    </div>
                    <div class="color_FF" v-if="superviseDetail.video == null || superviseDetail.video == ''">
                        <div v-html="superviseDetail.content"></div>
                    </div>
                    <div v-else class="flex flex-justify-center">
                        <video :src="superviseDetail.video" style="height: 49vh" controls></video>
                    </div>
                </div>
            </div>
        </div>

        <div class="msg-mid flex flex-v flex-justify-center flex-pack-center"
            v-if="this.$route.params.type == 7 || tmpdata == 7">

            <div class="my_text_center supervisedetail-box">
                <p class="
                        font-size28 font-blod
                        color_FF
                        msg-mid-title-bot-title
                    ">
                    {{ superviseDetail.title }}
                </p>
                <p class="font-size22 color_9FB msg-mid-title-bot-line">
                    {{ superviseDetail.releaseTime }} 发布
                </p>
                <div class="student-detail-box flex flex-pack-center flex-justify-around">
                    <div class="font-size22 color_9FB flex flex-pack-center">
                        <div class=" m_r20">
                            收集状态：
                        </div>
                        <div class=" flex flex-pack-center flex-justify-center"
                            :class="superviseDetail.status == 1 ? 'state_1' : superviseDetail.status == 2 ? 'state_2' : 'state_3'">
                            {{ superviseDetail.status == 1 ? "未开始" : superviseDetail.status == 2 ? "进行中" : "已完成" }}
                        </div>
                    </div>
                    <div class="font-size22 color_9FB flex flex-pack-center">
                        <div class="">
                            开始时间:
                        </div>
                        <div>
                            {{ superviseDetail.startTime }}
                        </div>
                    </div>
                    <div class="font-size22 color_9FB flex flex-pack-center">
                        <div class=" m_r20">
                            结束时间:
                        </div>
                        <div>
                            {{ superviseDetail.endTime }}
                        </div>
                    </div>
                    <div class="font-size22 color_9FB flex flex-pack-center">
                        <div class=" m_r20">
                            已收集：
                        </div>
                        <div>
                            {{ superviseDetail.showCollectionNum }}
                        </div>
                    </div>
                </div>
                <van-popup v-model="showDoc" style="width: 60vw; padding: 2vw" closeable>
                    <div v-if="isShowDoc">
                        <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=https://gyrd.itlic.com' +
                            superviseDetail.annex
                            " frameborder="0" width="100%" height="800"></iframe>
                    </div>
                    <div v-else>
                        <div>
                            <embed :src="superviseDetail.annex" width="100%" height="800" />
                        </div>
                    </div>
                </van-popup>

                <div class="supervisedetail-desc">
                    <div class="font-size28 color_FF flex flex-end m_r20 m_b10"
                        v-if="superviseDetail.annex != 'null' && superviseDetail.annex != '[]'">
                        <van-button size="small" plain type="primary" class="m_r20" @click="preview">附件预览</van-button>
                    </div>
                    <div class="color_FF" v-if="superviseDetail.video == null || superviseDetail.video == ''">
                        <div v-html="superviseDetail.content"></div>
                    </div>
                    <div v-else class="flex flex-justify-center">
                        <video :src="superviseDetail.video" style="height: 49vh" controls></video>
                    </div>
                </div>
            </div>
        </div>

        <div class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            ">
            <div class="my_text_center" @click="goBack">
                <img src="@/assets/images/return-new.png" alt="" class="msg-return m_b10" />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <!-- <div class="font-blod my_text_center color_FF"></div> -->
            <div v-if="superviseDetail.status == 2" class="complaint flex flex-justify-center flex-pack-center"
                @click="gotocomplaint">
                提意见
            </div>
            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { dateTimeFormat } from "@/request/until.js";
import fullScroll from "@/components/fullScroll.vue";

export default {
    components: { fullScroll },
    data() {
        return {
            tmpdata: "",//传递的类型id
            todayMtime: "",
            todayTime: "",
            superviseDetail: [],
            showDoc: false,
            isShowDoc: true
        };
    },
    created() {
        this.getTime();
    },
    mounted() {
        // 设置定时器更新时间
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);

        // 读取本地存储中的数据
        let superviseDetails = sessionStorage.getItem("superviseDetail");
        this.tmpdata = sessionStorage.getItem("tmpdata");
        let data = JSON.parse(superviseDetails);
        this.superviseDetail = data
        if (sessionStorage.getItem("hasNew") === "true") {
            this.superviseDetail.showCollectionNum += 1;
        }
        data.annex = JSON.parse(data.annex)
        if (Array.isArray(data.annex)) {
            data.annex = data.annex[0]
        }

    },
    methods: {
        gotocomplaint() {
            this.tnum = this.superviseDetail.id;
            sessionStorage.setItem("superviseDetail", JSON.stringify(this.superviseDetail));
            this.$router.push({
                path: "/wordmsg",
                query: { sourceType: 7, tnum: this.tnum }
            });
        },
        //返回首页
        backHome() {
            this.$router.push("/");
        },

        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },
        goBack() {
            this.$router.back();
        },

        //预览附件
        preview() {
            this.showDoc = true;
        }
    },
    /**
     * 缓存的页面
     */
    beforeRouteLeave(to, from, next) {
        if (to.path === "/law" && sessionStorage.getItem("hasNew")) {
            to.meta.keepAlive = false;
        }
        next();
    }
};
</script>
